<template>
    <div class="dashboard__block wall__item post" :class="data.view === 'post' ? 'wall__post' : ' wall__advert'">
        <div class="post__wrap">
            <div class="post__header">
                <author
                    :avatar="data.author.image_zoom_out"
                    :full-name="data.author.full_name"
                    :date="data.created_at"
                    :link="data.author.link"
                />
                <submenu
                    v-if="current_user.is_admin"
                    :on-delete="onDelete"
                    :post-id="data.id"
                    :on-edit="enableEdit"
                />
            </div>
            <div class="post__content">
                <img v-if="data.images.length === 1 && !isPostEditing" :src="data.images[0].file" class="post__preview" alt="">
                <gallery v-else-if="data.images.length > 1 && !isPostEditing" :img-list="data.images"/>
                <div class="post__text" v-if="!isPostEditing">
                    <div v-if="isAdvert" class="post__title">{{data.title}}</div>
                    <!--<read-more more-str="Показать полностью" :text="data.content" link="#" less-str="Скрыть"
                               :max-chars="500"/>-->
                    <div v-if="!isReadMoreActivated && data.content.length > 300" class="post__text">{{data.content.slice(0, 300)}}...</div>
                    <div v-if="isReadMoreActivated || data.content.length < 300" class="post__text">{{data.content}}</div>
                    <a v-if="!isReadMoreActivated && data.content.length > 300" href="javascript:void(0)" class="v-read-more" @click="isReadMoreActivated = true">
                        <span>Показать полностью</span>
                        <span class="v-angle-icon"/>
                    </a>
                </div>
                <edit-post
                    v-else
                    :data="data"
                    :is-advert="isAdvert"
                    :onSave="savePost"
                    :onCancel="cancelEdit"
                />
            </div>
            <!-- Кнопка "Понятно" со списком кликнувших для поста-объявления -->
            <v-popover
                trigger="hover"
                placement="right-center"
                :offset="1"
                v-if="isAdvert && !isPostEditing"
                :delay="{
                    hide:500
                }"
                :auto-hide="false"
                class="post__advert-popover"
                popoverInnerClass="submenu__body"
            >
                <button
                    class="v-btn post__advert-btn tooltip-target"
                    :disabled="data.is_clicked || understandAdvertLoading"
                    @click="understandAdvert"
                >
                    {{data.is_clicked ? `${data.clicked.count} ${num2str(data.clicked.count, ['отметка', 'отметки',
                    'отметок'])} "Понятно"` : 'Понятно'}}
                </button>

                <template slot="popover" class="post__advert-content">
                    <div
                        v-if="data.clicked.results.length && (current_user.is_admin || current_user.is_owner || current_user.is_chief)"
                        class="post__advert-list"
                    >
                        <router-link
                            v-for="clicked of data.clicked.results"
                            :key="clicked.id"
                            :to="clicked.user.link"
                            class="post__advert-user"
                            target="_blank"
                            :title="clicked.user.short_name"
                        >
                            <img class="post__advert-avatar" :src="clicked.user.image_zoom_out || defaultAvatar">
                        </router-link>
                        <a
                            v-if="data.clicked.count > 4"
                            href="javascript:void(0)"
                            class="post__advert-more"
                            @click="clickedListOpen"
                        >
                            +{{data.clicked.count-4}}
                        </a>
                    </div>
                </template>
            </v-popover>
        </div>
        <vote
            v-if="data.votes.length && !isPostEditing"
            :title-poll="data.title_poll"
            :vote-list="data.votes"
            :post-id="data.id"
        />
        <div v-if="!isPostEditing && !isAdvert" class="social__btns">
            <a
                href="javascript:void(0)"
                class="social__btn"
                title="Нравится"
                @click="() => onLike(data.id)"
            >
                <div class="social__icon social__icon--like"
                     :class="{'social__icon--liked':data.is_like}"></div>
                <div class="social__count">{{data.likes.count}}</div>
            </a>
            <a href="javascript:void(0)" class="social__btn" title="Комментарии">
                <div class="social__icon social__icon--comments"></div>
                <div class="social__count">{{data.comments ? data.comments.count : ''}}</div>
            </a>
        </div>
        <comments
            v-if="!isPostEditing && !isAdvert && data.comments"
            :comments="data.comments"
            :obj-id="data.id"
            content-type="wall"
            :update-comments-count="updateCommentsCount"
        />
    </div>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "Post",
        components: {
            Author : () => import('../Author'),
            Comments : () => import('../Comments/Comments'),
            Submenu : () => import('./Submenu'),
            EditPost : () => import('./EditPost'),
            Vote : () => import('./Vote'),
            Gallery : () => import('./Gallery')
        },
        data() {
            return {
                isPostEditing: false,
                isReadMoreActivated: false,
                isAdvert: this.data.view === 'advert',
                swiperOptionThumbs: {
                    loop: true,
                    loopedSlides: 5, // looped slides should be the same
                    spaceBetween: 10,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    touchRatio: 0.2,
                    slideToClickedSlide: true
                },
                defaultAvatar,
                understandAdvertLoading: false
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            },
            onLike: {
                type: Function,
                required: true
            },
            onDelete: {
                type: Function,
                required: true
            }
        },
        methods: {
            async updateCommentsCount(type) {
                const request = await session.get(`/api/v1/comment/count/?contentType=${type}&id=${this.data.id}`);
                this.data.comments.count = request.data.count
            },
            cancelEdit() {
                this.isPostEditing = false;
            },
            enableEdit() {
                this.isPostEditing = true;
            },
            savePost: async function (post) {
                try {
                    this.data = post;
                    if (this.data.votes.length) {
                        this.data.votes = this.data.votes.map(vote => {
                            return {
                                ...vote,
                                wall: this.data.id
                            }
                        })
                    }
                    const req = await session.put(`/api/v1/wall/${this.data.id}/`, this.data);
                } catch (error) {
                    console.error(error);
                }
                this.isPostEditing = false;
            },
            /* Метод для кнопки "Понятно" у поста-объявления*/
            async understandAdvert() {
                this.understandAdvertLoading = true
                try {
                    await session.post(`/api/v1/wall/${this.data.id}/clicked/`);

                    /* Обновить список кликнувших после клика "Понятно"*/
                    let request = session.get(`/api/v1/wall/${this.data.id}/`);
                    let clickedPost = await request;

                    this.data.clicked = clickedPost.data.clicked;
                    this.data.is_clicked = true;
                } catch (error) {
                    console.error(error);
                }
                this.understandAdvertLoading = false
            },
            clickedListOpen() {
                const UsersModal = () => import(`../../modals/UsersModal`);
                this.$modal.show(UsersModal, {
                    modalTitle: 'С объявлением ознакомились',
                    api: `/api/v1/wall/${this.data.id}/clicked/`
                }, {
                    name: 'AdvertClickedModal',
                    width: '500px',
                    height: '600px',
                })
            },
            /* Склонение числительных для слова "отметка" */
            num2str(n, text_forms) {
                n = Math.abs(n) % 100;
                var n1 = n % 10;

                if (n > 10 && n < 20) {
                    return text_forms[2];
                }

                if (n1 > 1 && n1 < 5) {
                    return text_forms[1];
                }

                if (n1 == 1) {
                    return text_forms[0];
                }

                return text_forms[2];
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ]),
        }
    }
</script>

<style lang="sass">
    @import "#sass/v-style"

    .post

        &__wrap
            padding: 30px

        &__header
            display: flex
            justify-content: space-between
            align-items: flex-start

        &__preview
            display: flex
            margin:
                left: auto
                right: auto
            max-width: 100%
            max-height: 500px

        &__content
            margin-top: 20px

        &__title
            font-size: 18px
            font-weight: 500
            margin-bottom: 15px

        &__text
            margin-top: 20px
            font-size: 1rem
            color: #000
            white-space: pre-wrap

        &__social
            display: flex
            margin-top: 20px

            &-item
                cursor: pointer
                display: flex
                align-items: center

                &:nth-child(n+2)
                    margin-left: 25px

                &:hover svg
                    opacity: 1

            &-icon
                opacity: 0.7
                transition: opacity 100ms ease-in-out

                &--active
                    fill: #FBC04F
                    stroke: #FBC04F

            &-count
                margin-left: 8px
                font-size: 1rem
                color: #A4AEBD

        &__advert
            &-btn
                white-space: nowrap
                padding:
                    right: 45px
                    left: 45px

                &:disabled
                    color: #000

            &-popover
                margin-top: 30px

            &-list
                display: flex
                align-items: center
                padding: 10px
                height: 100%

            &-user
                &:nth-child(n+2)
                    margin-left: 5px

            &-avatar
                height: 24px
                width: 24px
                border-radius: 50%

            &-more
                display: flex
                justify-content: center
                align-items: center
                height: 24px
                margin-left: 5px
                color: #000
                border: 1px solid #FBC04F
                border-radius: 50px
                padding: 0 5px
</style>
